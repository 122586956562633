<template>
  <el-form
    :inline="isInline"
    ref="dateForm"
    :model="dateForm"
    :rules="dateFormRule"
    label-width="0"
  >
    <el-form-item
      :label="ctrlBeginName"
      prop="BeginDate"
      :style="{marginBottom:mgb}"
    >
      <el-date-picker
        :size="dateSize"
        :type="dateType"
        :style="{width:dWidth}"
        v-model="dateForm.BeginDate"
        :placeholder="beiginHolder"
        :format="dateFormatter"
        :value-format="dateValueFormatter"
        @change="dateChange"
        @focus="onFocus"
      ></el-date-picker>
    </el-form-item>
    <el-form-item
      :style="{marginBottom:mgb}"
      v-if="isRange==true"
    >至</el-form-item>
    <el-form-item
      :label="ctrlEndName"
      prop="EndDate"
      v-if="isRange==true"
      :style="{marginBottom:mgb}"
    >
      <el-date-picker
        :size="dateSize"
        :type="dateType"
        :style="{width:dWidth}"
        v-model="dateForm.EndDate"
        placeholder="结束时间"
        :format="dateFormatter"
        :value-format="dateValueFormatter"
        @change="dateChange"
        @focus="onFocus"
      ></el-date-picker>
    </el-form-item>
  </el-form>
</template>
<script>
export default {
  model: {
    prop: "date",
    event: "dateChangeEvent"
  },
  props: {
    date: {
      default: {
        BeginDate: "",
        EndDate: ""
      }
    },
    dateSize: {
      default: "small"
    },
    inline: {
      default: true
    },
    dateWidth: {
      default: "200px"
    },
    isRange: {
      default: false
    },
    validNullOrEmpty: {
      default: false
    },
    beginName: {
      default: ""
    },
    endName: {
      default: ""
    },
    frequency: {
      default: ""
    }
  },
  data() {
    const dateValidator = (rule, value, callback) => {
      if (!value || value == "") {
        //是否验证空空
        if (this.nullOrEmpty) {
          callback(new Error("请选择时间"));
        }
      } else {
        if (this.selectedFrequency === "Quarter") {
          let quarter = value.split("-");
          if (
            quarter[1] != "03" &&
            quarter[1] != "06" &&
            quarter[1] != "09" &&
            quarter[1] != "12"
          ) {
            callback(new Error("季度月份必须为03、06、09、12"));
          }
        }
      }
      callback();
    };
    return {
      //dateForm:this.date,
      dateType: "",
      dateFormatter: "",
      dateValueFormatter: "",
      dateForm: this.date,
      dateFormRule: {
        BeginDate: [{ validator: dateValidator, trigger: "change" }],
        EndDate: [{ validator: dateValidator, trigger: "change" }]
      },
      dWidth: this.dateWidth,
      beiginHolder: this.isRange ? "起始时间" : "选择时间",
      nullOrEmpty: this.validNullOrEmpty,
      mgb: "0px",
      ctrlBeginName: this.beginName,
      ctrlEndName: this.endName,
      isInline: this.inline,
      selectedFrequency: this.frequency
    };
  },
  watch: {
    date: {
      handler(val) {
        this.dateForm = val;
      },
      immediate: true
    },
    frequency: {
      handler(val) {
        this.selectedFrequency = val;
        this.frequencyChange(val);
      },
      immediate: true
    }
  },
  methods: {
    setFrequency(value) {
      this.selectedFrequency = value;
      this.frequencyChange(this.selectedFrequency);
    },
    setDate(value) {
      this.date = value;
    },
    frequencyChange(val) {
      this.date.BeginDate = "";
      this.date.EndDate = "";
      if (val == "Year") {
        this.dateType = "year";
        this.dateFormatter = "yyyy";
        this.dateValueFormatter = "yyyy-01-01";
      } else if (val == "Month" || val == "Quarter") {
        this.dateType = "month";
        this.dateFormatter = "yyyy-MM";
        this.dateValueFormatter = "yyyy-MM-01";
      } else if (val == "Week") {
        this.dateType = "week";
        this.dateFormatter = "yyyy 第 WW 周";
        this.dateValueFormatter = "";
      } else {
        this.dateType = "date";
        this.dateFormatter = "yyyy-MM-dd";
        this.dateValueFormatter = "yyyy-MM-dd";
      }
    },
    formValid() {
      var ret = false;
      this.$refs["dateForm"].validate(valid => {
        ret = valid;
      });
      return ret;
    },
    dateChange() {
      this.$emit("dateChangeEvent", this.date);
    },
    resetFields() {
      this.$refs["dateForm"].resetFields();
    },
    clearValid() {
      this.$refs["dateForm"].clearValidate();
    },
    onFocus() {
    }
  },
  mounted() {
    this.mgb = this.inline || !this.isRange ? "0px" : "20px";
  }
};
</script>
    
