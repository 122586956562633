<template>
  <div class="loginfo-container">
    <!--工具条-->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px;">
      <el-form :inline="true" :model="filters">
        <!-- <el-form-item>
          <el-select size="small" v-model="filters.category" placeholder="请选择类别" style="width:150px">
            <el-option key label="全部类别" value></el-option>
            <el-option v-for="item in LogCategory" :key="item.Value" :label="item.Name" :value="item.Key">
              <span style="float: left; color: #8492a6; font-size: 13px">{{ item.Name }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select size="small" v-model="filters.type" placeholder="请选择级别" style="width:150px">
            <el-option key label="全部级别" value></el-option>
            <el-option v-for="item in LogType" :key="item.Value" :label="item.Name" :value="item.Key">
              <span style="float: left; color: #8492a6; font-size: 13px">{{ item.Name }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item> 
          <dateSelector v-model="filters.Date" ref="dateSelector" dateWidth="140px" :isRange="true"></dateSelector>
        </el-form-item>-->
        <el-form-item>
          <el-input size="small" v-model="filters.search" placeholder="关键字"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" v-on:click="queryPageList">查询</el-button>
        </el-form-item>
      </el-form>
    </el-col>
    <!--列表-->
    <el-table stripe border size="mini" :data="tableData" highlight-current-row expand-on-click-node="false" :header-cell-style="{
        'font-weight': '400',
        height: '40px',
        padding: '0',
        background: '#4a8bc1',
        color: 'white',
      }" style="width: 100%;">
      <el-table-column prop="Code" label="编号" width="100" :formatter="logFormatter"></el-table-column>
      <el-table-column prop="Name" label="姓名" min-width="100" :formatter="logTypeFormatter" sortable></el-table-column>
      <el-table-column prop="Age" label="年龄" min-width="100" sortable></el-table-column>
      <el-table-column prop="Sex" label="性别" min-width="100" sortable></el-table-column>
      <el-table-column prop="Height" label="身高(cm)" min-width="100" sortable></el-table-column>
      <el-table-column prop="Weight" label="体重(kg)" min-width="100" sortable></el-table-column>
      <el-table-column prop="JoinNo" label="参赛号" min-width="·00" sortable></el-table-column>
      <el-table-column prop="Description" label="说明" min-width="150" sortable></el-table-column>
      <el-table-column prop="InsertTime" label="报名时间" min-width="100" sortable>
        <template slot-scope="scope">
          {{format.dateTimeFormat(scope.row.InsertTime)}}
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-container">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="pageSizesList" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" :current-page.sync="pageIndex"></el-pagination>
    </div>
  </div>
</template>
<script>
import dateSelector from "@/components/dateSelector";
import { getPageList } from "@/api/pbsign";

export default {
  components: {
    dateSelector: dateSelector
  },
  data () {
    return {
      filters: {
        search: ""
      },
      tableData: [],
      total: 0,
      pageIndex: 1,
      pageSize: 10,
      pageSizesList: [10, 15, 20, 30, 50],
      dialogStatus: ""
    };
  },
  methods: {
    queryPageList: function () {
      let data = {
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
        search: this.filters.search
      };
      getPageList(data).then(response => {
        this.listLoading = false;
        if (response) {
          this.tableData = response.Items;
          this.total = response.Total||response.Items.length;
        }
      });
    },
    handleSizeChange (val) {
      var pageSize = `${val}`;
      this.pageIndex = 1;
      this.pageSize = parseInt(pageSize);
      this.$nextTick(() => this.queryPageList());
    },
    handleCurrentChange (val) {
      this.pageIndex = `${val}`;
      this.queryPageList();
    },
  },
  mounted () {
    this.queryPageList();
  }
};
</script>

<style lang='scss' scoped>
.loginfo-container {
  padding: 10px;
}
/deep/.el-dialog {
  margin-top: 10px !important;
}
</style>
